import { cx } from "classix";
import Link from "next/link";
import { ComponentProps } from "react";

export function ButtonLink(props: ComponentProps<typeof Link>) {
  return (
    <Link
      {...props}
      className={cx(
        "rounded text-white shadow hover:shadow-none",
        "bg-brand hover:bg-brand-dark focus:ring-brand dark:focus:ring-brand-dark cursor-pointer p-2 transition-colors focus:outline-none focus:ring focus:ring-opacity-50",
        props.className,
      )}
    >
      {props.children}
    </Link>
  );
}
