import { Switch as BaseSwitch } from "@headlessui/react";
import { cx } from "classix";
import { Dispatch, SetStateAction } from "react";

export function Switch(props: {
  checked: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
  "aria-hidden"?: boolean;
}) {
  return (
    <span>
      <BaseSwitch
        checked={props.checked}
        onChange={props.onChange}
        className={cx(
          props.checked ? "bg-brand dark:bg-brand-dark" : "bg-neutral-200 dark:bg-neutral-500",
          "focus:ring-brand dark:focus:ring-brand-dark relative inline-flex h-6 w-11 items-center rounded-full focus:outline-none focus:ring focus:ring-opacity-50",
        )}
        aria-hidden={props["aria-hidden"]}
      >
        <span
          className={cx(
            props.checked ? "translate-x-6" : "translate-x-1",
            "inline-block h-4 w-4 rounded-full bg-white",
          )}
        />
      </BaseSwitch>
    </span>
  );
}
