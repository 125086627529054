import DefaultSEO from "@/components/DefaultSeo";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import { Notifications } from "@/components/Notifications";
import type { ReactNode } from "react";

type Props = { children: ReactNode };

function FullBleedLayout({ children }: Props) {
  return (
    <>
      <DefaultSEO />
      <div className="bg-background flex min-h-full flex-col">
        <Navbar />
        <main className="py-8">{children}</main>
        <Footer />
      </div>
      <Notifications />
    </>
  );
}

export default FullBleedLayout;

export const getLayout = (page: any) => <FullBleedLayout>{page}</FullBleedLayout>;
