import { HeartIcon as HeartIconFull } from "@heroicons/react/20/solid";
import {
  ArrowDownTrayIcon,
  CalculatorIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  DevicePhoneMobileIcon,
  HeartIcon,
  HeartIcon as HeartIconEmpty,
  MoonIcon,
  SunIcon,
  SwatchIcon,
} from "@heroicons/react/24/outline";
import { cx } from "classix";
import Image from "next/image";
import Link from "next/link";
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import { tw } from "tw-lite";

import darkImage from "../../public/dark.png";
import lightImage from "../../public/light.png";

import CalculateFormation from "@/components/CalculateFormation";
import { Card } from "@/components/Card";
import { wantsDarkMode } from "@/components/DarkModeToggle";
import { InfoBadge } from "@/components/InfoBadge";
import { PlayerPillBase } from "@/components/PlayerPillBase";
import { Title } from "@/components/Title";
import { ButtonLink } from "@/components/buttons/ButtonLink";
import { Switch } from "@/components/inputs/Switch";
import { getLayout } from "@/layouts/FullBleedLayout";
import { t } from "@/locales/translate";

const Section = tw("section")`
  relative isolate my-16 grid h-[70vh] 2xl:h-[50vh] lg:grid-cols-2 container mx-auto
`;
const Prose = tw("div")`prose dark:prose-light prose-brand`;

interface Feature {
  title: string;
  body: ReactNode;
  Icon: typeof ArrowDownTrayIcon;
}

function FeatureCard({ feature, className }: { feature: Feature; className?: string }) {
  const { Icon } = feature;
  return (
    <Card className={className}>
      <article className="p-4 px-2 md:px-4">
        <div className="prose dark:prose-light prose-brand mb-4 flex items-center space-x-2">
          <Icon height={32} width={32} />
          <h2 className="!my-0">{feature.title}</h2>
        </div>
        {feature.body}
      </article>
    </Card>
  );
}

const ExampleExportTd = tw("td")`border border-neutral-300 !px-3 py-1 font-light`;

function ExampleExport(props: { className?: string }) {
  const rows = [
    ["$", "$", "$"],
    ["Hertha Vernello", 2451, 15],
    ["AC Vostra", 366, 5],
    ["Hertha Vernello", 1114, 7],
    ["Celta Vino", 5663, 35],
    ["Celta Vino", 450, 58],
    ["Celta Vino", 4582, 3],
    ["Sambuca Juniors", 145, 8],
    ["Sambuca Juniors", 2301, 19],
  ];
  return (
    <table
      className={cx("table-auto bg-white dark:bg-neutral-700", props.className)}
      aria-label={t("home.export.tableLabel")}
    >
      <tbody>
        {rows.map(r => (
          <tr key={r[1]}>
            <ExampleExportTd>{r[0]}</ExampleExportTd>
            <ExampleExportTd>{r[1]}</ExampleExportTd>
            <ExampleExportTd>{r[2]}</ExampleExportTd>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const examplePlayers: Player[] = [
  {
    id: 785,
    name: "Immobile",
    team: "Lazio",
    roleClassic: "A",
    rolesMantra: ["Pc"],
    amount: 89,
  },
  {
    id: 250,
    name: "Handanovic",
    team: "Inter",
    roleClassic: "P",
    rolesMantra: ["Por"],
    amount: 18,
  },
  {
    id: 367,
    name: "Bonaventura",
    team: "Fiorentina",
    roleClassic: "C",
    rolesMantra: ["C", "W", "T"],
    amount: 15,
  },
  {
    id: 86,
    name: "Bonucci",
    team: "Juventus",
    roleClassic: "D",
    rolesMantra: ["Dc"],
    amount: 8,
  },
  {
    id: 2819,
    name: "Caputo",
    team: "Sampdoria",
    roleClassic: "A",
    rolesMantra: ["Pc"],
    amount: 36,
  },
  {
    id: 2002,
    name: "Chiesa",
    team: "Juventus",
    roleClassic: "A",
    rolesMantra: ["W", "A"],
    amount: 41,
  },
  {
    id: 22,
    name: "De Roon",
    team: "Atalanta",
    roleClassic: "C",
    rolesMantra: ["M"],
    amount: 13,
  },
  {
    id: 464,
    name: "Florenzi",
    team: "Milan",
    roleClassic: "D",
    rolesMantra: ["Dd", "E"],
    amount: 6,
  },
  {
    id: 4292,
    name: "Hernandez T.",
    team: "Milan",
    roleClassic: "D",
    rolesMantra: ["Ds", "E"],
    amount: 21,
  },
  {
    id: 226,
    name: "Izzo",
    team: "Torino",
    roleClassic: "D",
    rolesMantra: ["Dd", "Dc"],
    amount: 10,
  },
  {
    id: 4495,
    name: "Kumbulla",
    team: "Roma",
    roleClassic: "D",
    rolesMantra: ["Dc"],
    amount: 4,
  },
  {
    id: 2788,
    name: "Bremer",
    team: "Juventus",
    roleClassic: "D",
    rolesMantra: ["Dc"],
    amount: 26,
  },
  {
    id: 2741,
    name: "Pessina",
    team: "Monza",
    roleClassic: "C",
    rolesMantra: ["C", "T"],
    amount: 31,
  },
  {
    id: 827,
    name: "Locatelli",
    team: "Juventus",
    roleClassic: "C",
    rolesMantra: ["M", "C"],
    amount: 29,
  },
  {
    id: 2764,
    name: "Martinez L.",
    team: "Inter",
    roleClassic: "A",
    rolesMantra: ["Pc"],
    amount: 74,
  },
];

function DarkModeToggle({
  isDark,
  setIsDark,
  className,
}: {
  isDark: boolean;
  setIsDark: Dispatch<SetStateAction<boolean>>;
  className?: string;
}) {
  const [animate, setAnimate] = useState(false);

  function handleClick() {
    setIsDark(!isDark);
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 210);
  }

  return (
    <div onClick={handleClick} className={cx("relative w-16 cursor-pointer", className)}>
      <div
        className={cx(
          "absolute bottom-2 z-0 h-4 w-full rounded-lg shadow-inner",
          isDark ? "bg-neutral-400 dark:bg-neutral-500" : "bg-neutral-300 dark:bg-neutral-400",
        )}
      />
      <button
        className={cx(
          "rounded-full bg-white p-2 shadow hover:shadow-sm",
          "absolute bottom-0 transition-colors duration-300",
          "z-10 focus:outline-none focus:ring",
          isDark ? "left-0 bg-blue-100 text-blue-900" : "right-0 bg-yellow-100 text-yellow-900",
          animate ? "motion-safe:animate-ripple-sm" : null,
        )}
        aria-label={t("home.darkMode.ariaLabel")}
      >
        {isDark ? <MoonIcon width={20} height={20} /> : <SunIcon width={20} height={20} />}
      </button>
    </div>
  );
}

function HomePage() {
  const [enableRoleLimits, setEnableRoleLimits] = useState(false);
  const [secondBidderWins, setSecondBidderWins] = useState(false);
  const [groupKeepersByTeam, setGroupKeepersByTeam] = useState(true);
  const [enableAuctionStages, setEnableAuctionStages] = useState(false);
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    setIsDark(wantsDarkMode());
  }, []);

  const features = {
    calculator: {
      title: t("home.calculator.title"),
      body: (
        <>
          <div className="prose dark:prose-light prose-brand">
            <p>{t("home.calculator.body")}</p>
          </div>
          <CalculateFormation players={examplePlayers} />
        </>
      ),
      Icon: CalculatorIcon,
    },
    chat: {
      title: t("home.chat.title"),
      body: (
        <div className="prose dark:prose-light prose-brand">
          <p>{t("home.chat.body")}</p>
        </div>
      ),
      Icon: ChatBubbleOvalLeftEllipsisIcon,
    },
    mobile: {
      title: t("home.mobile.title"),
      body: (
        <div className="prose dark:prose-light prose-brand">
          <p>{t("home.mobile.body")}</p>
        </div>
      ),
      Icon: DevicePhoneMobileIcon,
    },
    wishlist: {
      title: t("home.wishlist.title"),
      body: (
        <>
          <Prose>{t("home.wishlist.body")}</Prose>
          <div className="mt-4 space-y-2">
            <div className="relative w-full">
              <PlayerPillBase
                player={{
                  name: "Kvaratskhelia",
                  roleClassic: "A",
                  rolesMantra: ["A"],
                  team: "Napoli",
                  id: 1,
                }}
                gameMode="mantra"
              />
              <HeartIconFull className="text-error-600 dark:text-error-500 absolute bottom-0 right-4 top-3 h-4 w-4" />
            </div>
            <div className="relative w-full">
              <PlayerPillBase
                player={{
                  name: "Akpa Akpro",
                  roleClassic: "C",
                  rolesMantra: ["M", "C"],
                  team: "Monza",
                  id: 1,
                }}
                gameMode="mantra"
              />
              <HeartIconEmpty className="absolute bottom-0 right-4 top-3 h-4 w-4 text-neutral-600 dark:text-neutral-400" />
            </div>
          </div>
        </>
      ),
      Icon: HeartIcon,
    },
    customize: {
      title: t("home.options.title"),
      body: (
        <Prose as="div" className="!space-y-2">
          <div className="flex items-center">
            <p className="!my-0 w-full pr-4">{t("liveAuction.create.secondBidderWins.label")}</p>
            <Switch aria-hidden={true} checked={secondBidderWins} onChange={setSecondBidderWins} />
          </div>
          <div className="flex items-center">
            <p className="!my-0 w-full pr-4">{t("leagues.create.maxPlayersPerRole.label")}</p>
            <Switch aria-hidden={true} checked={enableRoleLimits} onChange={setEnableRoleLimits} />
          </div>
          <div className="flex items-center">
            <p className="!my-0 w-full pr-4">{t("leagues.create.groupKeepers.label")}</p>
            <Switch
              aria-hidden={true}
              checked={groupKeepersByTeam}
              onChange={setGroupKeepersByTeam}
            />
          </div>
          <div className="flex items-center">
            <p className="!my-0 w-full pr-4">{t("liveAuction.create.stages.label")}</p>
            <Switch
              aria-hidden={true}
              checked={enableAuctionStages}
              onChange={setEnableAuctionStages}
            />
          </div>
        </Prose>
      ),
      Icon: SwatchIcon,
    },
  };

  return (
    <>
      <div className="relative isolate px-6 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="from-brand-dark to-brand-600 relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-44">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <Link href="#news">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-700 ring-1 ring-gray-900/10 hover:ring-gray-900/20 dark:text-gray-300">
                {t("home.v3banner")}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Title className="text-4xl font-bold tracking-tight sm:text-6xl">
              {t("seo.description")}
            </Title>
            <p className="mt-6 text-lg leading-8 text-gray-700 dark:text-gray-300">
              {t("home.summary")}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <ButtonLink className="px-4" href="/login">
                {t("home.cta")}
              </ButtonLink>
              <a
                href="#features"
                className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100"
              >
                {t("home.learnMore")} <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
      <div className="relative isolate">
        <Section className="h-auto lg:h-[70vh]" id="features">
          <div className="space-y-4 place-self-center">
            <Title as="h2">{t("home.ui.title")}</Title>
            <Prose>{t("home.ui.body")}</Prose>
          </div>
        </Section>
        <div className="left-[55%] right-0 top-0 mt-4 h-full rounded-md lg:absolute">
          <Image
            src={lightImage}
            alt={t("home.darkMode.image.alt")}
            className="h-full select-none rounded-md object-cover object-left shadow"
          />
          <div
            className="absolute inset-0 h-full w-full rounded-md transition-all duration-300"
            style={{ clipPath: `inset(0 0 0 ${isDark ? 0 : 100}%)` }}
          >
            <Image
              src={darkImage}
              alt={t("home.darkMode.image.altDark")}
              className="h-full select-none rounded-md object-cover object-left shadow"
            />
          </div>
          <div className="absolute bottom-2 left-4 flex justify-center xl:justify-end">
            <DarkModeToggle className="mr-4" isDark={isDark} setIsDark={setIsDark} />
          </div>
        </div>
      </div>
      <Section>
        <div className="container space-y-4 place-self-center lg:order-2">
          <Title as="h2">{t("home.export.title")}</Title>
          <Prose>{t("home.export.body")}</Prose>
        </div>
        <ExampleExport className="w-full lg:order-1" />
      </Section>
      <section id="news" className="container my-24 flex">
        <div className="mx-auto space-y-8">
          <div className="flex items-center justify-center gap-4">
            <Title as="h2">{t("home.leagues.title")}</Title>
            <InfoBadge className="mt-3 text-sm">{t("home.new")}</InfoBadge>
          </div>
          <Prose>
            <p>{t("home.leagues.body")}</p>
            <ul>
              <li>{t("home.leagues.bullet1")}</li>
              <li>{t("home.leagues.bullet2")}</li>
              <li>{t("home.leagues.bullet3")}</li>
              <li>{t("home.leagues.bullet4")}</li>
              <li>{t("home.leagues.bullet5")}</li>
            </ul>
          </Prose>
        </div>
      </section>
      <section className="container relative my-16">
        <div className="prose dark:prose-light prose-brand mb-8">
          <Title as="h2">{t("home.more")}</Title>
        </div>
        <div className="-mx-4 grid grid-cols-1 gap-8 md:-mx-8 lg:grid-cols-3 lg:grid-rows-2 lg:gap-4 xl:gap-8">
          <FeatureCard feature={features.calculator} className="col-span-1 row-span-2" />
          <FeatureCard feature={features.chat} className="col-span-1 row-span-1" />
          <FeatureCard feature={features.wishlist} />
          <FeatureCard feature={features.customize} />
          <FeatureCard feature={features.mobile} />
        </div>
      </section>
    </>
  );
}
export default HomePage;
HomePage.getLayout = getLayout;
