import { cx } from "classix";
import { PropsWithChildren } from "react";
import { BaseIndicator } from "./MarketCard/BaseIndicator";

export function InfoBadge(props: PropsWithChildren<{ className?: string }>) {
  return (
    <BaseIndicator
      className={cx(
        "whitespace-nowrap bg-blue-200 text-blue-900 dark:bg-blue-800 dark:text-blue-50",
        props.className,
      )}
    >
      {props.children}
    </BaseIndicator>
  );
}
